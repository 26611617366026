import React from 'react'

import boarding from "../media/images/dog-luggage.png";

const PetBoardingComponent = () => {
  return (
         <div className="pet-boarding">
    <div>
        <div className=" boarding-heading-1">Best <span> Pet Boarding Centre</span> in Noida, NCR <span></span></div>
        <div className=" boarding-heading-2">
        {/* ARE YOU LOOKING FOR BOARDING SERVICE TO YOUR PETS? WELL, YOU ARE ON THE RIGHT PLACE. */}
        </div>
        
        <div className=" boarding-cols">
        <div className=" boarding-col-1">
          <img src={boarding} alt="" />
        </div>
        <div className=" boarding-col-2">
        <div className=" boarding-col-2-heading">
             {/* ARE YOU LOOKING FOR BOARDING SERVICE TO YOUR PETS? WELL, YOU ARE ON THE RIGHT PLACE. */}
             
Are you looking for boarding service to your pets? Well, you are on the right place.

             </div>
        <div className=" boarding-col-2-points">
        <div className=" boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Pick up & Drop Available</div>
        <div className=" boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Stress-Free Boarding</div>
        <div className=" boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Home-Like Comfort</div>
        <div className=" boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; 24/7 Supervision</div>
        <div className=" boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Trained Care Taker</div>
        <div className=" boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Veg & Non Veg Food</div>
          
        </div>
        </div>

        </div>
        
      </div>
    </div>
  )
}

export default PetBoardingComponent