import React from "react";

const PetBoardingFeatures = () => {

  return (
    <div>
      
      <div className="boarding-feature">
        <div>
          <div className="boarding-feature-heading">Features of our <span>Pet Boarding</span> Centre</div>
          <div className="boarding-feature-points">
            <div></div>
            <div>
              <div className="boarding-feature-points-text">
                <span>•</span>
                <div>Fully AC</div>
              </div>
              <div className="boarding-feature-points-text">
                <span>•</span>
                <div>24 * 7 CCTV access</div>
              </div>
              <div className="boarding-feature-points-text">
                <span>•</span>
                <div>Leash free stay</div>
              </div>
              <div className="boarding-feature-points-text">
                <span>•</span>
                <div>2 times walk</div>
              </div>
            </div>

            <div>
              <div className="boarding-feature-points-text">
                <span>•</span>
                <div>Meal as per your choice</div>
              </div>
              <div className="boarding-feature-points-text">
                <span>•</span>
                <div>Grooming facility</div>
              </div>
              <div className="boarding-feature-points-text">
                <span>•</span>
                <div>Daily housekeeping with strict protocols</div>
              </div>
              <div className="boarding-feature-points-text">
                <span>•</span>
                <div>Separate area for aggressive and large pets</div>
              </div>
            </div>
          </div>
          {/* <div className="boarding-feature-button"  onClick={e=>setOpenForm(true)}>Make an Appointment</div> */}
        </div>
      </div>
    </div>
  );
};

export default PetBoardingFeatures;
