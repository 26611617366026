import React from "react";
import { Link } from "react-router-dom";

const ContactFixButton = () => {
  return (
    <div>
      <div className="contact-button">
        
      <div className="call-button">
      <Link className="call-button-icon"  target="_blank" to="tel:+919910902899">
              <i class="fa-solid fa-phone-volume"></i>
          </Link>
    </div>


    <div className="whatsapp-button">
      <Link className="whatsapp-button-icon" target="_blank" to="https://wa.me/+919910902899?text=Hello ! I'm Interested in Pet Boarding Service. Please contact me.">
        <i class="fa-brands fa-whatsapp"></i>
      </Link>
    </div>
        
      </div>
    </div>
  );
};

export default ContactFixButton;
