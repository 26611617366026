import React from "react";
import HeaderComponent from "../components/HeaderComponent";
import Footer from "../components/Footer";
import PetBoardingFacility from "../components/PetBoardingFacility";
import PetBoardingFeatures from "../components/PetBoardingFeatures";
import PetBoardingComponent from "../components/PetBoardingComponent";
import PriceComponent from "../components/PriceComponent";
import TestimonialComponent from "../components/TestimonialComponent";
import ContactFixButton from "../components/ContactFixButton";
import GalleryComponent from "../components/GalleryComponent";
// import KeyWords from "../components/KeyWords";
import ClientSaysComponent from "../components/ClientSaysComponent";

const HomePage = () => {
  return (
    <div>
        <ContactFixButton/>
      <HeaderComponent />
      <ClientSaysComponent/>

      <PriceComponent />

      <PetBoardingComponent />
      <PetBoardingFeatures />
      <PetBoardingFacility />
      <TestimonialComponent/>
      <GalleryComponent/>

{/* <KeyWords/> */}
      <Footer />
    </div>
  );
};

export default HomePage;
