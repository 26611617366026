import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import { Link, useNavigate } from 'react-router-dom'

import logo_icon from "../media/images/ft-white-logo.png";
import LoadingComponent from '../components/LoadingComponent';


const DogBoardingFormPage = () => {

  const setMinSelectableDate = () => {
    const today = new Date().toISOString().slice(0, 16);
    return today;
  };
  
  const setMaxSelectableDate = () => {
    const today = new Date().toISOString().split('T')[0];
    return today;
  };
  
  
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    pet: "Dog",
    startDate: "",
    endDate: "",
    ownerName: "",
    ownerEmail: "",
    ownerNumber: "",
    ownerAddress: "",
    petName: "",
    petDob: "",
    petBreed: "",
    petColour: "",
    petDescription: "__no_description__",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
        const response = await fetch("https://api.boarding.fluffytouch.in/pet-boarding-form", {
        // const response = await fetch("https://api.shop.fluffytouch.in/pet-boarding-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        alert("Service booked successfully");
        navigate("/thanks");

      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }

    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };

  
  
  return (
    <div>

{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      
      
      
<Navbar/>


      
      <div className="boarding-form">
        <form onSubmit={handleSubmit}>
      <h1 className="boarding-form-heading-1">Fluffy Touch Dog Boarding Form</h1>

      <h2>Extra charges for Aggressive Dog </h2>
      
      <div className="boarding-form-input">
        <label>Boarding Start*</label>
        <input required name='startDate' onChange={handleChange}  min={setMinSelectableDate()} type="datetime-local"/>
        
      </div>

      <div className="boarding-form-input">
        <label>Boarding End*</label>
        <input required name='endDate' onChange={handleChange}  min={setMinSelectableDate()} type="datetime-local" />
      </div>

      <div className="boarding-form-heading-1">Dog Owner Information</div>

      <div className="boarding-form-input">
        <label>Dog Owner's Name*</label>
        <input required name='ownerName' onChange={handleChange}  type="text" placeholder='your full name'/>
      </div>


      <div className="boarding-form-input">
        <label>Dog Owner's Email*</label>
        <input required name='ownerEmail' onChange={handleChange}  type="email" placeholder='your email'/>
      </div>


      <div className="boarding-form-input">
        <label>Dog Owner's Number*</label>
        <input required name='ownerNumber' onChange={handleChange}  type="number" placeholder='your mobile number'/>
      </div>


      <div className="boarding-form-input">
        <label>Dog Owner's Address*</label>
        <textarea required name='ownerAddress' onChange={handleChange}  type="text" placeholder='your address'/>
      </div>

      <div className="boarding-form-heading-1">Dog Information</div>


      <div className="boarding-form-input">
        <label>Dog's Name*</label>
        <input required name='petName' onChange={handleChange}  type="text" placeholder="dog's name"/>
      </div>


<div className="boarding-form-input">
  <label>Dog D.O.B.*</label>
  <input required name='petDob' onChange={handleChange} max={setMaxSelectableDate()} type="date" placeholder="dog's date of birth"/>
</div>


      <div className="boarding-form-input">
        <label>Breed Type*</label>
        <input required name='petBreed' onChange={handleChange}  type="text" placeholder="dog's breed"/>
      </div>


      <div className="boarding-form-input">
        <label>Dog's Colour*</label>
        <input required name='petColour' onChange={handleChange}  type="text" placeholder="dog's colour"/>
      </div>


      <div className="boarding-form-input">
        <label>Special Request</label>
        <textarea name='petDescription' onChange={handleChange}  type="text" placeholder="dog's description"/>
      </div>

<button>Submit</button>

        </form>
      </div>


      {/* <Footer/> */}
      <div className="footer-main">

      <div>

      <div className="footer-content">
          
          <div className="footer-c-col-1">
            <div className="footer-logo">
              <Link title="Dog Haircut" to="/" className="footer-logo-icon">
                <img title="Dog Spa" src={logo_icon} alt="logo-icon" />
              </Link>
              
            </div>

            <div className="footer-social">
              <Link title="Pet Spa"
                target="_blank"
                to="https://www.facebook.com/Fluffytouchoffc/"
              >
                <i class="fa-brands fa-facebook"></i>
              </Link>
              <Link title="Dog Grooming At Home"
                target="_blank"
                to="https://www.instagram.com/fluffytouchofficial?igsh=ZGNjOWZkYTE3MQ=="
              >
                <i class="fa-brands fa-instagram"></i>
              </Link>
              <Link title="Local Dog Groomers"
                target="_blank"
                to="https://twitter.com/i/flow/login?redirect_after_login=%2Ffluffytouchoffc"
              >
                <i class="fa-brands fa-x-twitter"></i>
              </Link>
              <Link title="Self Service Dog Wash Near Me"
                target="_blank"
                to="https://www.linkedin.com/company/fluffytouch/"
              >
                <i class="fa-brands fa-linkedin-in"></i>
              </Link>
              <Link title="Dog Grooming Services"
                target="_blank"
                to="https://www.youtube.com/channel/UCcUZcYkiAv8t5ruDxYvVn_A"
              >
                <i class="fa-brands fa-youtube"></i>
              </Link>
              <Link title="Groom Professional" target="_blank" to="https://pin.it/3ZIwZk4">
                <i class="fa-brands fa-pinterest"></i>
              </Link>
            </div>
          </div>

          

          <div className="footer-c-cc">
            Copyright <i class="fa-regular fa-copyright"></i> 2019, FLUFFY
            TOUCH. All Rights Reserved.
          </div>
        </div>
        
      </div>
      </div>
      
      
    </div>
  )
}

export default DogBoardingFormPage