import React from "react";
import icon from "../media/images/google-icon.png";
import { Link } from "react-router-dom";

const TestimonialComponent = () => {
  return (
    <div className="testimonial-component">
        <div>
        <div className="testimonial-component-heading">Customers <span>Speaks</span> </div>
        <div className="testimonial-component-header">
          <div className="testimonial-component-header-col-1">
            <div className="testimonial-component-header-col-1-heading">
              Customer testimonial on Google
            </div>
            <div className="testimonial-component-header-col-1-text">
              <span>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </span>
              &nbsp; 4.9 Rating on Google
            </div>
          </div>
          <div className="testimonial-component-header-col-2">
            <Link
              target="_blank"
              to="https://www.google.com/search?q=Fluffy+touch+-+Pet+Grooming+and+Boarding+service+in+Noida&stick=H4sIAAAAAAAA_-NgU1I1qDC2NEhONU8BkikGJimm5lYGFUbm5sYG5qmJaeZmZqYmKSmLWC3dckrT0ioVSvJLkzMUdBUCUksU3Ivy83Mz89IVEvNSFJzyE4tSQJzi1KKyzORUhcw8Bb_8zJREAE5lOxxlAAAA&hl=en-GB&mat=Ca2MutlY8MWoElcBezTaAZQ0E5y9C6q0nVBFFAYrPGV_-U2N4MvyTc5vuu3MaigDXbMt2uzCzvXjzLKdNHwuzmp0jwas8gRkPM2dvIUm2GmTiA7pGMHsR-fbCx0czSm9A3c&authuser=0#lrd=0x390ce7d90cd04d57:0x277307eaf76654dd,1,,,,"
              className="testimonial-component-header-col-2-button"
            >
              Review us on Google
            </Link>
          </div> 
        </div>

        <div className="testimonial-component-comments">

            {/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img src={icon} alt="icon" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
            Exceptional Labrador boarding services at Fluffy Touch in Sector 99, Noida! Our furry friend was pampered and cared for with love. The grooming services added ...&nbsp;
            <br />
              <Link target="_blank" to="https://maps.app.goo.gl/R9rC6Ne7FefG5GKu5">read more on Google..</Link>
            </div>
            <div className="testimonial-component-comments-col-name">Tushar Chaudhary</div>
            <div className="testimonial-component-comments-col-date">a month ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img src="https://lh3.googleusercontent.com/a-/ALV-UjUn5TGfwI-trLtjQspEQZMWBhaEVkLSE_PlG80dis7an5qh_dIn=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
          {/* ---------- end ------------ */}

          

{/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img src={icon} alt="" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
              
            We recently had the pleasure of utilizing the Doodle dog Day Boarding services at Fluffy Touch, and we couldn't be more impressed. The staff, especially ...
            <br />
              &nbsp;<Link target="_blank" to="https://maps.app.goo.gl/gkySTQ9qHiwYbSyH9">read more on Google...</Link>
            </div>
            <div className="testimonial-component-comments-col-name">Deepanshu Joshi</div>
            <div className="testimonial-component-comments-col-date">2 months ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img src="https://lh3.googleusercontent.com/a/ACg8ocKd1bw_XtSD8KH5-gDGuRGtKPcakVOvBNZGbO5lGwamLvT9CphZ=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
{/* ---------- end ------------ */}


{/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img src={icon} alt="" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
            Exceptional pet grooming experience at Fluffy Touch in Faridabad! The well-trained and certified groomers provided top-notch care for my furry friend...
            <br />
              &nbsp;<Link target="_blank" to="https://maps.app.goo.gl/ASQMY9ZJXXWPnx8WA">read more on Google...</Link>
            </div>
            <div className="testimonial-component-comments-col-name">shaikh Love</div>
            <div className="testimonial-component-comments-col-date">2 months ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img src="https://lh3.googleusercontent.com/a/ACg8ocL4AUYOHVDOrjrjSAwBmasvtfUab6-MJXMJlV7JUBFb5frOcw=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
{/* ---------- end ------------ */}


{/* ---------- start ------------ */}
          {/* <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img src={icon} alt="" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
                My experience was the greatest I've ever had. My pets are so adorable. I'm really happy for my doggu. The entire Fluffy Touch team is incredible. My overall experience with Fluffy Touch has 
              &nbsp;<Link  to="https://maps.app.goo.gl/yfiSDbUxgoJ2FAun6">read more..</Link>
            </div>
            <div className="testimonial-component-comments-col-name">Tushar Chaudhary</div>
            <div className="testimonial-component-comments-col-date">a year ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img src="https://lh3.googleusercontent.com/a-/ALV-UjXEVmknMutyOpGFVZra0F8DVRTrvRcpI0oLniqweOBQyUM=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div> */}
{/* ---------- end ------------ */}


        </div>
      </div>
    </div>
  );
};

export default TestimonialComponent;
