import React from 'react'
import logo from "../media/images/ft-red-logo.png";
import { Link } from 'react-router-dom';


const Navbar = () => {
  return (
    <div>
        <div className="p-boarding-header-nav">
          <Link to="/" className="p-boarding-header-nav-logo">
            <img src={logo} alt="" />
          </Link>
          <Link target="_blank" to="tel:+91 99109 02899" className="p-boarding-header-nav-btn" >Book On Call</Link>
        </div>
    </div>
  )
}

export default Navbar