import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage'
import './App.css';
import './css/HeaderComponent.css';
import './css/PriceComponent.css';
import './css/PetBoardingComponent.css';
import './css/PetBoardingFacility.css';
import './css/PetBoardingFeatures.css';
import './css/Testimonial.css';
import './css/BookingForm.css';
import './css/Footer.css';
import './css/Loading.css';
import './css/ConfirmBooking.css';
import './css/ErrorPage.css';
import './css/ContactFixButton.css';
import './css/Animation.css';
import './css/ScrollTopButton.css';
import './css/Gallery.css';
import './css/KeyWords.css';
import './css/ClientSaysComponent.css';
import './css/BoardingForm.css';

import ErrorPage from './pages/ErrorPage';
import ConfirmBooking from './pages/ConfirmBookingPage';
import ScrollButton from './components/ScrollButton';
import DogBoardingFormPage from './pages/DogBoardingFormPage';
import CatBoardingFormPage from './pages/CatBoardingFormPage';

const App = () => {
  return (
    <div className='web-app'>
       <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/booking-confirm' element={<ConfirmBooking />}></Route>
          <Route path='/dog-boarding-form' element={<DogBoardingFormPage />}></Route>
          <Route path='/cat-boarding-form' element={<CatBoardingFormPage />}></Route>
          <Route path='/thanks' element={<ConfirmBooking />}></Route>

          <Route path='/*' element={<ErrorPage />}></Route>
        </Routes>
        <ScrollButton/>
       </BrowserRouter>
    </div>
  )
}

export default App