import React, { useState } from 'react'
import BookingForm from './BookingForm'


const PriceComponent = () => {
 const [openForm, setOpenForm] = useState(false)

 const [service, setService] = useState("")

  return (
    <div>
        {
            openForm?
<BookingForm setOpenForm={setOpenForm} service={service}/>
:""
        }


        <div className="package">
            <div>

                <div className="package-head">
                    {/* <div className="package-head-icon">
                        <i className="fa-solid fa-paw"></i>
                    </div> */}
                    <div className="package-head-heading">Our <span>Boarding</span> & <span> Care</span></div>
                    <div className="package-head-text">WE OFFER THE BEST BOARDING AND CARE SERVICES FOR YOUR PETS.</div>
                </div>


                <div className="package-content">
                  
                    <div className="package-content-list">
                        <div className="package-content-list-heading"><span>Pet Boarding</span> Price</div>
                        {/* <div className="package-content-list-heading-1">Get Free Pet Grooming (worth Rs. 2,200/-) on 7-Days Boarding Service</div> */}

                           
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Day Boarding</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 799</s> Rs. 499/-</div>
                                    <div className="package-content-list-col-point">8 Am to 6 Pm, 1 meal included</div>
                                    <div className="package-content-list-col-button" onClick={e=>{setOpenForm(true); setService("Day Boarding - Rs. 499/-")}}>Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Per Day Boarding</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 1,199</s> Rs. 699/-</div>
                                    <div className="package-content-list-col-point">Any day of your choice</div>
                                    <div className="package-content-list-col-button" onClick={e=>{setOpenForm(true); setService("Per Day Boarding - Rs. 699/-")}}>Book Appointment</div>
                                </div>
                            </div>

                            {/* <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Silver Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 6,500</s> Rs. 3,999/-</div>
                                    <div className="package-content-list-col-point">Any 7 days in a year</div>
                                    <div className="package-content-list-col-button" onClick={e=>{setOpenForm(true); setService("Silver Plan - Rs. 3,999/-")}}>Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Gold Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 12,000</s> Rs. 6,999/-</div>
                                    <div className="package-content-list-col-point">Any 14 days in a year</div>
                                    <div className="package-content-list-col-button" onClick={e=>{setOpenForm(true); setService("Gold Plan - Rs. 6,999/-")}}>Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Diamond Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 22,000</s> Rs. 11,999/-</div>
                                    <div className="package-content-list-col-point">Any 30 days in a year</div>
                                    <div className="package-content-list-col-button" onClick={e=>{setOpenForm(true); setService("Diamond Plan - Rs. 11,999/-")}}>Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Platinum </div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 60,000</s> Rs. 29,999/-</div>
                                    <div className="package-content-list-col-point">Any 90 days in a year</div>
                                    <div className="package-content-list-col-button" onClick={e=>{setOpenForm(true); setService("Platinum - Rs. 29,999/-")}}>Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Black Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 2,00,000</s> Rs. 99,999/-</div>
                                    <div className="package-content-list-col-point">Long term 1 year plan (365 days)</div>
                                    <div className="package-content-list-col-button" onClick={e=>{setOpenForm(true); setService("Black Plan - Rs. 99,999/-")}}>Book Appointment</div>
                                </div>
                            </div> */}

                        </div>

      


                    </div>


                </div>


            </div>
        </div>
    </div>
  )
}

export default PriceComponent