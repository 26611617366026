import React, { useState } from "react";

import img1 from "../media/images/bor-pet.png";
import LoadingComponent from "./LoadingComponent";
import { useNavigate } from "react-router-dom";

const BookingForm = ({setOpenForm, service}) => {
  //----------------------form  --------------------

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    pet: "",
    name: "",
    phone: "",
    service: service,
    breed: "",
    address: "",
    request: "",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch("https://api.boarding.fluffytouch.in/booking-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        // setBookingForm(false);
        alert("Service booked successfully");
        navigate("/booking-confirm");

      } else {
        // alert("invalid credential...");
        const res_data = await response.json();
        alert(res_data.message);
        // console.log("className=",res_data.message)
      }

      // console.log(response);
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);
  };

  // ------------------------------------------------------

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      <div className="groom-book-main">
        <div className="groom-book">
          <div className="groom-book-border-img">
            <img src={img1} alt="" />
          </div>
          <div className="groom-book-form">
            <div className="groom-book-form-img">
              {/* <img src={img} alt="img" /> */}
            </div>

            <div className="groom-book-form-content">
              <div className="groom-book-form-content-head">
                <div className="groom-book-form-content-head-col">
                  {/* <div className="groom-book-form-content-head-heading-1">
                Hello there,
              </div> */}
                  <div className="groom-book-form-content-head-heading-2">
                    Book Boarding For Your Pet 
                  </div>
                </div>

                <div className="groom-book-form-content-head-col">
                  <button onClick={e=>{setOpenForm(false)}}>
                    <i class="fa-solid fa-circle-xmark"></i>
                  </button>
                </div>
              </div>

              <div className="groom-book-form-content-form">
                <form onSubmit={handleSubmit}>
                  <div className="groom-book-form-content-form-col1">
                    <label>Your Pet*</label>
                    <div className="groom-book-form-content-form-col1-inp">
                      <div className="groom-book-form-content-form-col1-inp-1">
                        <input
                          required
                          type="radio"
                          name="pet"
                          onChange={handleChange}
                          value="Dog"
                        />
                        <span>DOG</span>
                      </div>
                      <div className="groom-book-form-content-form-col1-inp-1">
                        <input
                          required
                          type="radio"
                          name="pet"
                          onChange={handleChange}
                          value="Cat"
                        />
                        <span>CAT</span>
                      </div>
                    </div>
                  </div>

                  <div className="groom-book-form-content-form-col2">
                    <div className="groom-book-form-content-form-col2-col">
                      <label>Name</label>
                      <input required
                        type="text"
                        placeholder="your name"
                        name="name"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="groom-book-form-content-form-col2-col">
                      <label>Phone*</label>
                      <input
                        required
                        type="number"
                        placeholder="your mobile number"
                        name="phone"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="groom-book-form-content-form-col3">
                    <label>Interested in*</label>

                    {service}
                    
                    {/* <select
                      required
                      name="service"
                      onChange={handleChange}
                      id=""
                    >
                      <option disabled selected value="">
                        --- select service ---
                      </option>
                      
                      <option value="DAY BOARDING (8am - 6pm)">DAY BOARDING (8am - 6pm)</option>
                      <option value="PER DAY (1 Day)">PER DAY (1 Day)</option>
                      <option value="SILVER PLAN (7 Days)">SILVER PLAN (7 Days)</option>
                      <option value="GOLD PLAN (14 Days)">GOLD PLAN (14 Days)</option>
                      <option value="DIAMOND PLAN (30 Days)">DIAMOND PLAN (30 Days)</option>
                      <option value="PLATINUM (90 Days)">PLATINUM (90 Days)</option>
                      <option value="BLACK PLAN (365 Days)">BLACK PLAN (365 Days)</option>
                    </select> */}
                  </div>

                  <div className="groom-book-form-content-form-col5">
                    <label>Breed*</label>
                    <input
                      required
                      placeholder="What's your pet breed"
                      name="breed"
                      onChange={handleChange}
                    ></input>
                  </div>

                  <div className="groom-book-form-content-form-col5">
                    <label>Address*</label>
                    <input
                      required
                      placeholder="city / address"
                      rows="1"
                      name="address"
                      onChange={handleChange}
                    ></input>
                  </div>

                  <div className="groom-book-form-content-form-col5">
                    <label>Special Request</label>
                    <textarea
                      placeholder="any special request"
                      rows="1"
                      name="request"
                      onChange={handleChange}
                    ></textarea>
                    {/* <input type="text" placeholder="message or request" name="" id="" /> */}
                  </div>

                  <button>Book Now</button>
                </form>

                {/* <form>
                <div className="groom-book-form-col">
                  <div className="groom-book-form-col-title">Your Pet *</div>
                  <div className="groom-book-form-col-options">
                    <input type="radio" name="pet" id="" />
                    <label>DOG</label>
                    <input type="radio" name="pet" id="" />
                    <label>CAT</label>
                  </div>
                </div>
                <div className="groom-book-form-col">
                  <input type="number" placeholder="enter your number" />
                  <input type="text" placeholder="enter your name" />
                </div>
                <div className="groom-book-form-col">
                  <div className="groom-book-form-title">Interested In</div>
                  <div className="groom-book-form-option">
                    <select name="" id="">
                      <option disabled selected>
                        --- select service ---
                      </option>
                      <option>PAMPER PAWS</option>
                      <option>FUR FRESH SPA</option>
                      <option>BASIC GROOMING</option>
                      <option>FULL BODY GROOMING</option>
                      <option>DELUXE BODY GROOMING</option>
                    </select>
                  </div>
                </div>

                <div className="groom-book-form-col">
                  <div className="groom-book-form-title">Any Add-ons</div>
                  <div className="groom-book-form-option">
                    <select name="" id="">
                      <option disabled selected>
                        {" "}
                        --- Any Add-Ons ---
                      </option>
                      <option>Tick Removal by Hand</option>
                      <option>30 Min DeStressing Massage</option>
                      <option>DeMatting</option>
                    </select>
                  </div>
                </div>

                <div className="groom-book-form-col">
                  <input
                    type="text"
                    placeholder="any message !"
                    name=""
                    id=""
                  />
                </div>
                <button>Book Now</button>
              </form> */}
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default BookingForm;
