import React, { useState } from "react";

import logo from "../media/images/ft-red-logo.png";
import img from "../media/images/dog png.png";
import BookingForm from "./BookingForm";
import { Link, useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";

const HeaderComponent = () => {
 const [openForm, setOpenForm] = useState(false)

  //----------------------form  --------------------

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

 const [user, setUser] = useState({
  pet: "",
  name: "",
  phone: "",
  service: "",
  breed: "",
  address: "",
  request: "",
  user: "true",
  status: "true",
});

const handleChange = (e) => {
  const value = e.target.value;
  setUser({
    ...user,
    [e.target.name]: value,
  });
};

const handleSubmit = async (e) => {
  setLoading(true);

  e.preventDefault();
  try {
    const response = await fetch("https://api.boarding.fluffytouch.in/booking-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    if (response.ok) {
      // setBookingForm(false);
      alert("Service booked successfully");
      navigate("/booking-confirm");

    } else {
      // alert("invalid credential...");
      const res_data = await response.json();
      alert(res_data.message);
      // console.log("className=",res_data.message)
    }

    // console.log(response);
  } catch (error) {
    // console.log("catch err", error);
    alert("server error");
  }
  setLoading(false);
};

// ------------------------------------------------------

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
              {
            openForm?
<BookingForm setOpenForm={setOpenForm}/>
:""
        }

      <div className="p-boarding-header">
        {/* <div className="p-boarding-header-top">
        <div className="p-boarding-header-top-left">
          Get Free Pet Grooming (worth Rs. 2,200/-) on 7-Days Boarding Service
          </div>
        <div className="p-boarding-header-top-right">
        *Limited Period Offer
          </div>
          </div> */}
        <div className="p-boarding-header-nav">
          <div className="p-boarding-header-nav-logo">
            <img src={logo} alt="" />
          </div>
          <Link target="_blank" to="tel:+91 99109 02899" className="p-boarding-header-nav-btn" >Book On Call</Link>
        </div>
        <div>

          <div className="p-boarding-header-content">
            <div className="p-boarding-header-col-1">
              <img src={img} alt="" />
            </div>
            <div className="p-boarding-header-col-2">
              <div className="p-boarding-header-col-2-content">
                <h1 className="p-boarding-header-col-2-heading-1 p-boarding-header-col-2-heading-1-animation">
                  Cage-Free Pet <span>Boarding</span> <br />
                </h1>
                <div className="p-boarding-header-col-2-heading-1">
                  in all over <span>Delhi NCR</span>
                </div>
                {/* <div className="p-boarding-header-col-2-heading-1">in all over Delhi Ncr</div> */}
                {/* <div className="p-boarding-header-col-2-heading-2">Leave Your Pet in Safe Hands</div> */}
                <div className="p-boarding-header-col-2-heading-2">
                  Enhance your furry friend's boarding experience with Fluffy
                  Touch Safe Hands
                </div>
                <h2 className="p-boarding-header-col-2-heading-3">
                  <div>Pet Boarding at Just Rs. 29/-</div>{" "}
                </h2>
                <div className="p-boarding-header-col-2-columns">
                  <div className="p-boarding-header-col-2-col">
                    <div className="p-boarding-header-col-2-col-icon">
                      {/* <i class="fa-solid fa-indian-rupee-sign"></i> */}
                      <i class="fa-solid fa-volleyball"></i>
                    </div>
                    <div className="p-boarding-header-col-2-col-text">
                      {/* Boarding Rs. 29 per hour */}
                      {/* 2 Time Walking & Playing */}
                      Tailored Playtime
                    </div>
                  </div>

                  <div className="p-boarding-header-col-2-col">
                    <div className="p-boarding-header-col-2-col-icon">
                      <i class="fa-solid fa-video"></i>
                    </div>
                    <div className="p-boarding-header-col-2-col-text">
                      24*7 Camera Live Feed
                    </div>
                  </div>

                  <div className="p-boarding-header-col-2-col">
                    <div className="p-boarding-header-col-2-col-icon">
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    <div className="p-boarding-header-col-2-col-text">
                      Cage Free Boarding
                    </div>
                  </div>

                  <div className="p-boarding-header-col-2-col">
                    <div className="p-boarding-header-col-2-col-icon">
                      <i class="fa-solid fa-bowl-food"></i>
                    </div>
                    <div className="p-boarding-header-col-2-col-text">
                      Meal as per choice
                    </div>
                  </div>
                </div>

                {/* <div className="p-boarding-header-col-2-btn">
                  <span onClick={e=>setOpenForm(true)}>Book an Appointment</span>
                </div> */}
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="p-boarding-form">
        <form onSubmit={handleSubmit}>
          <div className="p-boarding-form-col-2">
            <input required type="text"  name="name" onChange={handleChange} placeholder="Name" />
            <input required type="number" name="phone" onChange={handleChange} placeholder="Number" />
            <select required name="pet" onChange={handleChange} id="">
              <option value="" selected disabled>
                your pet
              </option>
              <option value="Dog">Dog</option>
              <option value="Cat">Cat</option>
            </select>
            <select required name="address" onChange={handleChange} id="">
              <option value="" selected disabled>
                location
              </option>
              <option value="Delhi">Delhi</option>
              <option value="Noida">Noida</option>
              <option value="Greater Nodia">Greater Noida</option>
              <option value="Gurugram">Gurugram</option>
              <option value="Ghaziabad">Ghaziabad</option>
              <option value="Faridabad">Faridabad</option>
            </select>
            <button>Book Now</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HeaderComponent;
