import React from "react";
import img from '../media/images/boarding.png'

const PetBoardingFacility = () => {
  return (
    <div>
      <div className="boarding-facility">
        <div>
          <div className="boarding-facility-content">
            <div className="boarding-facility-col">
            <div className="boarding-facility-col-heading">Our <span>Facility</span> For <span>Boarding</span> Centre</div>
            <div className="boarding-facility-col-text-1">If you pet has a special routine of meal please do let us know we will adhere to it.</div>
            <div className="boarding-facility-col-text-2">Our meal program is based on vet recommendation globally, as per American Kennel Club there should be a gap of 8-12 hrs between meals for adult pets.</div>

            </div>
            <div className="boarding-facility-col">
                <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PetBoardingFacility;
