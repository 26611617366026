import React from "react";
// import logo_icon from "../media/images/ft-red-logo.png";
import logo_icon from "../media/images/ft-white-logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="footer-main">
        <div className="footer-content">
          {/* <svg
            id="clouds-bottom"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="100%"
            height="6vw"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path
              d="M-5 100 Q 0 20 5 100 Z
      M0 100 Q 5 0 10 100
      M5 100 Q 10 30 15 100
      M10 100 Q 15 10 20 100
      M15 100 Q 20 30 25 100
      M20 100 Q 25 -10 30 100
      M25 100 Q 30 10 35 100
      M30 100 Q 35 30 40 100
      M35 100 Q 40 10 45 100
      M40 100 Q 45 50 50 100
      M45 100 Q 50 20 55 100
      M50 100 Q 55 40 60 100
      M55 100 Q 60 60 65 100
      M60 100 Q 65 50 70 100
      M65 100 Q 70 20 75 100
      M70 100 Q 75 45 80 100
      M75 100 Q 80 30 85 100
      M80 100 Q 85 20 90 100
      M85 100 Q 90 50 95 100
      M90 100 Q 95 25 100 100
      M95 100 Q 100 15 105 100 Z"
            ></path>
          </svg> */}

          <div className="footer-c-col-1">
            <div className="footer-logo">
              <Link to="/" className="footer-logo-icon">
                <img src={logo_icon} alt="logo-icon" />
              </Link>

              {/* <div className="footer-logo-text">
                <div className="footer-logo-text-1">
                  FLUFFY <span>TOUCH</span>
                </div>
                <div className="footer-logo-text-2">
                  your pet deserve the best
                </div>
              </div> */}
              
            </div>

            <div className="footer-social">
              <Link
                target="_blank"
                to="https://www.facebook.com/Fluffytouchoffc/"
              >
                <i class="fa-brands fa-facebook"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/fluffytouchofficial?igsh=ZGNjOWZkYTE3MQ=="
              >
                <i class="fa-brands fa-instagram"></i>
              </Link>
              <Link
                target="_blank"
                to="https://twitter.com/i/flow/login?redirect_after_login=%2Ffluffytouchoffc"
              >
                <i class="fa-brands fa-x-twitter"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/fluffytouch/"
              >
                <i class="fa-brands fa-linkedin-in"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.youtube.com/channel/UCcUZcYkiAv8t5ruDxYvVn_A"
              >
                <i class="fa-brands fa-youtube"></i>
              </Link>
              <Link target="_blank" to="https://pin.it/3ZIwZk4">
                <i class="fa-brands fa-pinterest"></i>
              </Link>
            </div>
          </div>

          <div className="footer-c-col-2">
            <div className="footer-c-c2-section">
              <div className="footer-c-c2-s-heading">INFORMATION</div>
              <div className="footer-c-c2-s-points  ">
                <Link target="_blank" to="https://fluffytouch.in/">Fluffy Touch</Link>
                <Link target="_blank" to="https://fluffytouch.in/price">Grooming Price</Link>
                <Link target="_blank" to="https://fluffytouch.in/pet-boarding">Pet Boarding</Link>
                <Link target="_blank" to="https://fluffytouch.in/blog">Latest Blog</Link>
                <Link target="_blank" to="https://fluffytouch.in/about">About Us</Link>
                <Link target="_blank" to="https://fluffytouch.in/contact">Contact Us</Link>
              </div>
            </div>

            <div className="footer-c-c2-section">
              <div className="footer-c-c2-s-heading">AVAILABLE IN</div>
              <div className="footer-c-c2-s-points">
                <div>Delhi</div>
                <div>Noida</div>
                <div>Greater Noida</div>
                <div>Gurugram</div>
                <div>Ghaziabad</div>
                <div>Faridabad</div>
              </div>
            </div>

            <div className="footer-c-c2-section">
              <div className="footer-c-c2-s-heading">EXPLORE</div>
              <div className="footer-c-c2-s-points ">
                <Link to="https://fluffytouch.in/blog/best-dog-for-home" target="_blank">Best Dog for Home</Link>
                <Link to="https://fluffytouch.in/blog/friendliness-dog-breeds" target="_blank">Friendliness Dog Breeds</Link>
                <Link to="https://fluffytouch.in/blog/if-dog-coughing" target="_blank">If Your Dog Is Coughing?</Link>
                <Link to="https://fluffytouch.in/blog/top-popular-dog-india" target="_blank">Popular Dog Breeds in India</Link>
                <Link to="https://fluffytouch.in/blog/spot-and-treat-dog-allergies" target="blank_">Spot And Treat Dog Allergies</Link>
              </div>
            </div>

            <div className="footer-c-c2-section">
              <div className="footer-c-c2-s-heading">CORPORATE OFFICE</div>
              <div className="footer-c-c2-s-points">
                <div>
                  <span>
                    <i class="fa-solid fa-location-arrow"></i>
                  </span>
                  A, 77, near SUPREME TOWERS, <br />Block A, Sector 99, Noida, <br />Uttar Pradesh 201303
                  
                </div>
                <div>
                  <span>
                    <i class="fa-solid fa-mobile-screen-button"></i>
                  </span>{" "}
                  099109 02899
                </div>
                <div>
                  <span>
                    <i class="fa-solid fa-envelope"></i>{" "}
                  </span>
                  info@fluffytouch.in
                </div>
                <div>
                  <span>
                    <i class="fa-brands fa-whatsapp"></i>
                  </span>{" "}
                  +91 99109 02899
                </div>
              </div>
            </div>

            {/* <div className="footer-c-c2-section">
              <div className="footer-c-c2-s-heading">JOIN US</div>
              <div className="footer-c-c2-s-points">
                <div className="footer-c-c2-s-para">
                  Join us to get the best Pet Grooming <br /> service at your
                  location by experts. And <br />
                  Get pet boarding service for your pet.
                </div>
                  <form>
                <div className="footer-c-c2-s-form">
                  <input type="number" placeholder="your number" />
                  <button>Join Us</button>
                </div>
                  </form>
              </div>
            </div> */}


          </div>

          <div className="footer-c-cc">
            Copyright <i class="fa-regular fa-copyright"></i> 2019, FLUFFY
            TOUCH. All Rights Reserved.
          </div>
        </div>
      </div>
      <div className=""></div>
      <div className=""></div>
    </div>
  );
};

export default Footer;
